import logo from './logo.svg';
import successful from './successful_connection.png'
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="main-container">
        <div className="Img-container">
          <img src={successful} className="App-logo" alt="Successful Connection" />
        </div>
        <div className="Footer-container">
          <p className="Footer-text">You can go back to your workspace and use Trailmoji App again</p>
        </div>
      </div>
    </div>
  );
}

export default App;

//  <div>
// <p>Successful Connection!!</p>
// </div>
